/* Select */
.select {
  border: 0;
  outline: 1px solid var(--dark-gray-color);
  font: inherit;
  padding: 10px;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  color: var(--dark-gray-color);
}

.select:focus {
  border: none;
  outline: 1px solid var(--primary-font-color);
}

.icon {
  font-size: 100px;
  z-index: 1000;
}


