/* Table */
.table {
  width: 100%;
  position: relative;
  background-color: #fafafa;
  margin: 0;
  border-spacing: 0;
  border: 1px solid var(--dark-gray-color);
  color: var(--dark-gray-color);
  overflow-y: auto;
  height: 300px;
  font-size: 12px;
}

.table table {
  width: 100%;
  text-overflow: ellipsis;
}

.table td {
  padding: 20px;
  border-bottom: 1px solid #cfcfcf;
}

.table tr {
  width: 100%;
  height: 50px;
  text-overflow: ellipsis;
}

.labels {
  font-weight: 700;
  text-overflow: ellipsis;
}

/* Columns */
.label-name,
.label-date {
  width: 30%;
}

.label-button {
  width: 10%;
}

/* Loading screen */
.loading {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--dark-gray-color);
  width: 100%;
}

.padding {
  padding: 40px;
}

.mobile-button {
  display: none;
}

@media screen and (max-width: 730px) {
  .third-col {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .label-button {
    width: 5%;
  }

  .padding {
    padding: 20px;
  }

  .desk-button {
    display: none;
  }

  .fourth-col {
    display: none;
  }

  .first-col {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .mobile-button {
    display: flex;
  }

}

@media only screen and (max-width: 400px) {
  .first-col  {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
}