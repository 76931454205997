.top-control {
  display: flex;
  justify-content: flex-end;
}
.note-review-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 650px) {
  .note-review-top {
    flex-direction: column;
    text-align: center;
  }
}
