.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 10px;
}

.forgot-password {
  color: #679bda;
  font-size: 14px;
}

.forgot-password:hover {
  color: #4076B7;
}
