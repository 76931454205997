.form {
    display: flex;
    flex-direction: column;
}

.submit {
    background-color: #96C5FD;
    font-family: Nunito;
    font-weight: 700;
    border: 0px;
    color: white;
    padding: 15px;
    border-radius: 15px;
}

.submit:hover {
    background-color: #679bda;
}

.input {
    margin-bottom: 10px;
}
  

