.db-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
}

.grid-1 {
  grid-column: 1 / 4;
}

.grid-2 {
  grid-column: 4 / -1;
}

.grid-1,
.grid-2 {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

@media only screen and (max-width: 850px) {
  .db-grid {
    display: flex;
    column-gap: 0;
    row-gap: 20px;
    flex-direction: column;
  }
}
