.notes {
  text-transform: uppercase;
  font-size: 10px;
  color: var(--dark-gray-color);
}

.list {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 25px;
}

.note-block {
  list-style-type: none;
  background-color: var(--off-white-color);
  word-break: break-all;
  width: 200px;
  height: 200px;
  padding: 20px;
  overflow-y: auto;
  border: 1px solid var(--light-gray-color);
}

.title {
  border-bottom: 1px solid var(--light-gray-color);
  padding-bottom: 20px;
  word-break: break-all;
}

@media screen and (max-width: 500px) {
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
