/* Text */
.title {
  font-weight: 700;
  color: white;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-tag {
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 10px;
  font-size: 8px;
  background-color: rgb(255, 86, 193);
  padding: 2px 5px;
}

.box-change {
  position: relative;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.box-change:hover {
  color: var(--light-gray-color)
}

/* Buttons */
.buttons {
  display: flex;
  gap: 5px;
}

.open-box-button {
  position: relative;
}

.open-box-button:hover {
  transform: scale(1.02);
}

.selected-count {
  position: absolute;
  color: white;
  padding: 5px 9px;
  border-radius: 100px;
  font-size: 12px;
  top: -10px;
  left: -10px;
  background-color: rgb(255, 86, 193);
}
