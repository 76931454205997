.alert {
  width: 300px;
}

.float-wrapper {
  position: fixed; 
  z-index: 1000000; 
  left: 25px;
  top: 0;
  width: 100%; 
  overflow: auto; 
}

.float {
  margin: 0% auto; 
  padding-top: 10px;
  width: 300px;
  z-index: 1000000;
}

@media only screen and (max-width: 550px) {
  .float-wrapper {
    left: 0;
  }

  .alert {
    width: 90%;
    text-align: center;
  }
}