
.login h1 {
  margin: 0px;
  font-size: 32px;
}

.login p {
  margin: 0px;
  font-size: 14px;
  color: #948D8D;
  padding-bottom: 15px;
}

.google {
  display: flex;
  justify-content: center;
}

.signup {
  display: flex;
  font-size: 14px;
}

.signup-link {
  padding-left: 5px;
  color: #679BDA;
}
.signup-link:hover {
  color: #4076B7;
}

a {
  text-decoration: none;
}
