.modal-content {
  background-color: #fefefe;
  position: fixed;
  width: 500px;
  height: 500px;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -250px;
  z-index: 20000;
  overflow-y: auto;
}

/* Bg */
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
}

.modal-bg-color {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
}

/* Close */
.close {
  color: var(--dark-gray-color);
}

.close:hover {
  color: var(--light-gray-color);
}

@media screen and (max-width: 500px) {
  .modal-content {
    width: 400px;
    height: 400px;
    margin-top: -200px;
    margin-left: -200px;
  }
}

@media screen and (max-width: 500px) {
  .modal-content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
  }

}
