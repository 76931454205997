.input {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  grid-row-gap: 25px;
  grid-column-gap: 20px;
  border-bottom: 1px solid var(--light-gray-color);
}

.submit {
  display: flex;
  grid-column: 1 / -1;
  justify-content: center;
}
@media only screen and (max-width: 450px) {
  .input {
    grid-template-columns: 100%;
  }
}

