.wrapper {
  display: flex;
  flex-direction: column;
}

.activity {
  display: flex;
  width: 100%;
  color: var(--dark-gray-color);
  padding: 15px 0;

  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 8px;
}

.activity:not(:last-child) {
  border-bottom: 1px solid var(--light-gray-color);
}

/* Part 1 */
.part-1 {
  display: flex;
  gap: 10px;
}

.act-user {
  text-transform: lowercase;
  font-size: 12px;
}

.icon-wrap {
  display: flex;
}

.act-icon {
  background-color: var(--light-gray-color);
  color: white;
  font-size: 25px;
  padding: 5px;
  border-radius: 3px;
}

/* Part 2 */
.package {
  background-color: var(--bright-pink-color);
  padding: 5px 10px;
  color: white;
}

.no-activity {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--light-gray-color);
  min-height: 250px;
}