/* Top controls */
.top-controls {
  display: flex;
  justify-content: flex-end;
}

/* Center loading */
.loading-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgb(242, 164, 211);
  align-items: center;
}

/* New CSS */
.open-wrapper {
  position: fixed;
  top: 0;
  left: 50px;
  width: 100%;
  height: 100%;
  padding-right: 50px;
  background-color: rgb(242, 164, 211);
}

/* New CSS */
.position {
  position: relative;
  height: 100%;
  width: 100%;
}

.flash {
  position: fixed;
  top: 0;
  left: 0;
  animation: blinker 1s linear infinite;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.animated-package {
  width: 100%;
  height: 100%;
}

/* Bottom */
.bottom {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 100px;
  left: 0;
}

/* Text */
.title {
  font-weight: 700;
  color: white;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-tag {
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 10px;
  font-size: 8px;
  background-color: rgb(255, 86, 193);
  padding: 2px 5px;
}

.box-change {
  position: relative;
  margin-left: 10px;
  display: flex;
  justify-content: center;
}

/* Buttons */
.buttons {
  display: flex;
  gap: 5px;
}

.open-box-button {
  position: relative;
}

.open-box-button:hover {
  transform: scale(1.02);
}

.selected-count {
  position: absolute;
  color: white;
  padding: 5px 9px;
  border-radius: 100px;
  font-size: 12px;
  top: -10px;
  left: -10px;
  background-color: rgb(255, 86, 193);
}

@media only screen and (max-width: 550px) {
  .open-wrapper {
    left: 0px;
    padding: 0;
  }
}
