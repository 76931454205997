.nav {
  display: flex;
  background-color: var(--bright-pink-color);
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}

.nav a {
  color: white;
  transition: all 0.3s ease;
}
