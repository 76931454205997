.submit {
  background-color: #96C5FD;
  font-family: Nunito;
  font-weight: 700;
  border: 0px;
  color: white;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  margin-bottom: 10px;
}

.submit:hover {
  background-color: #679bda;
}


.input {
  margin-top: 20px;
  margin-bottom: 20px;
}

.login {
  color: #679bda;
  display: flex;
  justify-content: center;
  width: 100%;
}

.login:hover {
  color: #4076b7;
}
