/* Content */
.modal-content {
  background-color: #fefefe;
  position: fixed;
  width: 350px;
  height: 350px;
  top: 50%;
  z-index: 10000;
  left: 50%;
  margin-top: -175px;
  margin-left: -175px;
  padding: 20px;
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
}

/* Title */
.title {
  width: 100%;
  text-align: left;
}

/* Confirm options */
.text-confirm {
  color: var(--dark-gray-color);
  border: 2px solid var(--light-gray-color);
  background-color: var(--off-white-color);
  margin: 10px 0;
  overflow-y: auto;
  word-break: break-all;
  padding: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.char-limit {
  color: var(--light-gray-color);
  margin-top: 2px;
  margin-bottom: 5px;
  justify-self: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  font-size: 10px;
  text-transform: uppercase;
}

/* Buttons */
.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

/* Modal bg */
.modal-bg {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  z-index: 7500;
  width: 100%;
  height: 100%;
  overflow: none;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 350px) {
  .modal-content {
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    justify-content: flex-start;
    gap: 20px;
    margin: 0;
  }
}
